

























































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Print from 'vue-print-nb';
Vue.use(Print);
@Component
export default class Result extends Vue {
  result: any = {
    Id: '',
    No: '',
    Title: '',
    Amount: 0,
    IsPayed: false
  };
  isShowpay: boolean = false;
  paymentMethod: any = {};
  paymentMethods: any[] = [];

  getSubmitComplete (id) {
    this.$Api.regAndPay.getSubmitComplete(id).then(result => {
      this.result = result.data.ReturnValue;
      if (
        result.data.ReturnValue.Amount > 0 &&
        !result.data.ReturnValue.IsPayed
      ) {
        this.loadPaymentMethod();
      } else if (this.result.NextFormKey) {
        let NextFormKey = this.result.NextFormKey;
        setTimeout(() => {
          this.$router.push({ path: `/regnpay/form/${NextFormKey}` });
        }, 3000);
      }
    });
  }

  // 付款方式
  loadPaymentMethod () {
    this.$Api.checkout.getPaymentMethod().then(result => {
      this.paymentMethods = result.Payment;
      if (result.Payment.length > 0) {
        this.paymentMethod = result.Payment[0];
      }
    });
  }

  // 立即支付
  pay () {
    if (!this.paymentMethod.Id || this.paymentMethod.Id === '') {
      alert('请选择付款方式');
    } else {
      this.$Api.regAndPay
        .savePayMethod(this.paymentMethod.Id, this.result.Id)
        .then(result => {
          if (result.data.Succeeded) {
            // this.$router.push({ path: '/payment/' + this.paymentMethod.Code + '/' + this.result.Id });
            window.open(
              '/PG/pay/' +
                this.paymentMethod.Code +
                '/ISRNP/' +
                this.result.Id
            );
          } else {
            // alert(result.data.Message);
            // this.$Confirm('', '支付失败，请稍后再试！');
            this.$Confirm('', result.data.Message);
          }
        });
    }
  }

  printThis () {
    // alert('print!');
    // $('#myprintarea').printArea();
  }

  mounted () {
    this.getSubmitComplete(this.$route.params.id);
  }
}
