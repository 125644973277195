








import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component
export default class InsRegAndPay extends Vue {
  private htmlString: string = '';
  IsPay: boolean = false;
  getForm () {
    this.$Api.regAndPay
      .getHtml(this.$route.params.id, this.currentlang, this.isMobile).then(result => {
        this.htmlString = result.HtmlString;
        this.IsPay = result.IsPay;
        this.$nextTick(() => {
          document.dispatchEvent(new Event('rnpFinshed'));
        });
      });
  }
  get currentlang () {
    return this.$Storage.get('locale');
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }

  created () {
    this.getForm();

    window['jsData'] = {
      HasPreview: true,
      UploadButtonText: this.$t('regnpay.UploadButtonText'),
      UploadingText: this.$t('regnpay.UploadingText'),
      UploadSuccessfulText: this.$t('regnpay.UploadSuccessfulText'),
      UploadFailText: this.$t('regnpay.UploadFailText'),
      NoFileText: this.$t('regnpay.NoFileText'),
      UploadLengthText: this.$t('regnpay.UploadLengthText'),
      UploadSizeText: this.$t('regnpay.UploadSizeText'),
      BackText: this.$t('regnpay.BackText'),
      ConfirmText: this.$t('regnpay.ConfirmText'),
      PleaseSelect: this.$t('regnpay.PleaseSelect'),
      Version: '2.0'
    };
  }
  mounted () {
    window['regAndPay'] = this.$Api.regAndPay;
    window['router'] = this.$router;
    // window['getPanel'] = this.$Api.getPanel;

    window['Elalert'] = this.$alert;

    // eslint-disable-next-line no-unused-expressions
    window['save'];
    // eslint-disable-next-line no-unused-expressions
    window['checkForm'];
    // eslint-disable-next-line no-unused-expressions
    window['preview'];
    // eslint-disable-next-line no-unused-expressions

    // 引入在线js
    const oScript = document.createElement('script');
    oScript.type = 'text/javascript';
    // oScript.src = 'http://pm.dev.in-store.hk:84/scripts/store/regnpay.js';
    oScript.src = 'https://admin.uat.in-store.hk/scripts/store/regnpay.js';
    document.body.appendChild(oScript);
  }
}
